/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Packages from "./packages";
import History from "./history";
import ExpectValue from "./expect-value";
import VTabs from "components/v-core/v-tabs";
import { useHistory } from "react-router-dom";
import { MasterContext } from "context/MasterContext";
import ComingSoon from "components/coming-soon";
import { SiFuturelearn } from "react-icons/si";
import { FiPackage } from "react-icons/fi";
import { AiOutlineHistory } from "react-icons/ai";
import Dividends from "./dividends";

interface IProps {
  [key: string]: any;
}
const tabs = [
  {
    name: "Packages",
    value: "packages",
    icon: <FiPackage size={17} />,
  },
  {
    name: "Histories",
    value: "histories",
    icon: <AiOutlineHistory size={17} />,
  },
  {
    name: "Expect Value",
    value: "expect_value",
    icon: <SiFuturelearn size={15} />,
  },
];

export default function Trust(props: IProps) {
  const { getTabPositon } = useContext(MasterContext);
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");

  useEffect(() => {
    if (!currentTab) history.push("?tab=packages");
  });
  const handleTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
  };

  return (
    <div className="p-2">
      {getTabPositon("tab_page_trust").length > 0 ? (
        <VTabs
          tabs={getTabPositon("tab_page_trust").map(
            (item: any, index: number) => ({
              name: item.name,
              value: item.key,
              icon: <img src={item?.img_icon} />,
            })
          )}
          tabActive={currentTab}
          onChangeTabs={handleTabs}
        />
      ) : (
        <VTabs tabs={tabs} onChangeTabs={handleTabs} tabActive={currentTab} />
      )}
      <div className="mt-3">
        {currentTab === "packages" && <Packages />}
        {currentTab === "histories" && <History />}
        {currentTab === "expect_value" && <ExpectValue />}
        {currentTab === "dividends" && <Dividends />}
        {currentTab === "stock_certificates" && <ComingSoon />}
      </div>
    </div>
  );
}

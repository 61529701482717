import USER from "./users";
import AUTH from "./auth";
import DOCUMENT from "./documents";
import CRYPTO from "./cryptos";
import NFT from "./nfts";
import UPLOAD from "./uploads";
import KYC from "./kycs";
import TRANSACTION from "./transactions";
import SETTING from "./settings";
import WALLET from "./wallets";
import STORE from "./store"
import PURCHASE from "./purchases";
import PAYMENT from "./payments";
import PRODUCT from "./products";
import BANK from "./banks";
import YOURESTOCK from "./yourEstock";
import PROJECT from "./projects";
import TASK from "./tasks";
import ADVERTISEMENT from "./advertisements";
import NOTIFICATION from "./notifications";
import INVEST from "./invest";
import SUPPORT from "./supports";
import EVENT from "./events";
import REVIEW from "./review-page";
import TRADING from "./trading";

const APIs = {
  USER,
  AUTH,
  DOCUMENT,
  CRYPTO,
  NFT,
  UPLOAD,
  KYC,
  TRANSACTION,
  SETTING,
  WALLET,
  STORE,
  PURCHASE,
  PAYMENT,
  PRODUCT,
  BANK,
  YOURESTOCK,
  PROJECT,
  TASK,
  ADVERTISEMENT,
  NOTIFICATION,
  INVEST,
  SUPPORT,
  EVENT,
  REVIEW,
  TRADING
};

export default APIs;

/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Verification from "./components/Verification";
import Affiliates from "./referral";
import Setting from "./setting";

import ObjectInfo from "./object";
import SettingChannel from "./channel";
import Info from "./info";
import CardVisit from "./visitcard";
import ChangePassword from "./changepassword";
import VTabs from "components/v-core/v-tabs";
import { CgProfile } from "react-icons/cg";
import { AiOutlineSetting, AiOutlineIdcard } from "react-icons/ai";
import { TbActivity, TbAffiliate } from "react-icons/tb";
import { MdOutlineVerified } from "react-icons/md";
import { Si1Password } from "react-icons/si";
import { IoCardOutline } from "react-icons/io5";

import VTable from "components/v-core/v-table";
import BankCard from "./bank_card";
import { MasterContext } from "context/MasterContext";
import { current } from "@reduxjs/toolkit";

const columns = [
  {
    name: "Action",
    field: "action",
  },
  {
    name: "Source",
    field: "source",
  },
  {
    name: "Ip address",
    field: "ip_address",
  },
  {
    name: "Location",
    field: "location",
  },
  {
    name: "Updated at",
    field: "updated_at",
  },
];

const tabs = [
  {
    name: "Profile",
    value: "profile",
    icon: <CgProfile size={17} />,
  },
  {
    name: "Visitcard",
    value: "visit_card",
    icon: <AiOutlineIdcard size={17} />,
  },
  {
    name: "Affiliate",
    value: "affiliate",
    icon: <TbAffiliate size={17} />,
  },
  {
    name: "Verification",
    value: "verification",
    icon: <MdOutlineVerified size={17} />,
  },
  {
    name: "ChangePassword",
    value: "change_password",
    icon: <Si1Password size={17} />,
  },
  {
    name: "Setting",
    value: "setting",
    icon: <AiOutlineSetting size={17} />,
  },
  {
    name: "Activity",
    value: "activity",
    icon: <TbActivity size={17} />,
  },
  // {
  //   name: "Channel",
  //   value: "channel",
  //   icon: <AiOutlineSetting size={17} />,
  // },
  {
    name: "Bank Card",
    value: "bank_card",
    icon: <IoCardOutline size={17} />,
  },
];

const View = () => {
  const { getTabPositon } = useContext(MasterContext);
  const [tabActive, setTabActive] = useState(null);

  useEffect(() => {
    setTabActive(tabs[0].value);
  }, []);
  const handleTabs = (tab: string) => {
    setTabActive(tab);
  };

  return (
    <>
      <div className="md:px-4 w-full flex flex-col">
        <div>
          {getTabPositon("tab_page_profile").length > 0 ? (
            <VTabs
              tabActive={tabActive}
              tabs={getTabPositon("tab_page_profile").map(
                (item: any, index: number) => ({
                  name: item.name,
                  value: item.key,
                  icon: <img src={item?.img_icon} />,
                })
              )}
              onChangeTabs={handleTabs}
            />
          ) : (
            <VTabs
              tabs={tabs}
              onChangeTabs={handleTabs}
              tabActive={tabActive}
            />
          )}
        </div>
        <div className="md:px-4 px-2 w-full my-10 flex justify-center ">
          {tabActive === "profile" && <Info />}
          {tabActive === "visit_card" && <CardVisit />}
          {tabActive === "verification" && <Verification />}
          {tabActive === "change_password" && <ChangePassword />}
          {tabActive === "activity" && (
            <div className="overflow-auto flex flex-col w-full">
              <h6>All activity list</h6>
              <VTable columns={columns} data={[]}></VTable>
            </div>
          )}
          {tabActive === "setting" && <Setting />}
          {tabActive === "affiliate" && <Affiliates />}
          {tabActive === "object" && <ObjectInfo />}
          {tabActive === "channel" && <SettingChannel />}
          {tabActive === "bank_card" && <BankCard />}
        </div>
      </div>
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { BsListTask, BsClockHistory } from "react-icons/bs";
import Statis from "./Statis";
import Revenues from "./Revenues";
import VTabs from "components/v-core/v-tabs";
import { useHistory } from "react-router-dom";
import { MasterContext } from "context/MasterContext";

const View = () => {
  const { getTabPositon } = useContext(MasterContext);
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");

  const tabs = [
    {
      name: "Statistical",
      value: "statis",
      icon: <BsListTask size={17} />,
    },
    {
      name: "Revenues",
      value: "revenues",
      icon: <BsClockHistory size={17} />,
    },
  ];

  const handleTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
  };

  useEffect(() => {
    if (!currentTab) {
      history.push("?tab=statis");
    }
  }, [currentTab]);

  return (
    <div className="p-[5px] md:py-0 md:px-2 w-full rounded-md">
      {getTabPositon("tab_page_sales_statistics").length > 0 ? (
        <VTabs
          tabs={getTabPositon("tab_page_sales_statistics").map(
            (item: any, index: number) => ({
              name: item.name,
              value: item.key,
              icon: <img src={item?.img_icon} />,
            })
          )}
          onChangeTabs={handleTabs}
          tabActive={currentTab}
        />
      ) : (
        <VTabs tabs={tabs} onChangeTabs={handleTabs} tabActive={currentTab} />
      )}

      {currentTab === "statis" && (
        <div className="pt-4 min-w-full">
          <Statis />
        </div>
      )}
      {currentTab === "revenues" && (
        <div className="pt-4">
          <Revenues />
        </div>
      )}
    </div>
  );
};

export default View;

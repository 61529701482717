import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getStore = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/store/all/${req.params.userId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createTransferStore = (data) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/store/transfer`, data)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTransferStore = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/store/transfer/${req.params.userId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateTransferStore = (userId, storeId, data) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/store/transfer/${userId}/${storeId}`, data)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteTransferStore = (userId, storeId) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`/store/transfer/${userId}/${storeId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  getStore,
  createTransferStore,
  getTransferStore,
  updateTransferStore,
  deleteTransferStore,
};

export default APIs;

import React, { useState } from "react";
import VButton from "components/v-core/v-button";
import Histories from "../histories";

type IProps = {
  type?: string;
  tabActive?: string;
  exchange?: any[];
  selectedTab?: string;
  tabs?: any[];
  handleTabChange?: (value: string) => void;
};

const ModalHistory = ({ exchange, selectedTab, handleTabChange }: IProps) => {
  const [openDialog, setOpenDialog] = useState("");
  const [isExiting, setIsExiting] = useState(false);

  const handleOpenDialog = (type: any) => {
    if (isExiting) return;
    setIsExiting(true);
    setTimeout(() => {
      setOpenDialog(type);
      setIsExiting(false);
    }, 500);
  };

  const handleCloseDialog = () => {
    setIsExiting(true);
    setTimeout(() => {
      setOpenDialog("");
      setIsExiting(false);
    }, 500);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex gap-2">
        <span
          onClick={() => handleOpenDialog("modal2")}
          className="text-white py-1 px-2 rounded"
        >
          <img className="h-6 w-6" src="/home.png" alt="" />
        </span>
      </div>
      <div>
        {openDialog === "modal2" && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div
              className={`bg-black-1 p-6 rounded-lg shadow-lg absolute right-0 w-full h-full transform transition-transform duration-300 ease-in-out ${
                isExiting ? "translate-x-full" : "translate-x-0"
              }`}
            >
              <Histories
                data={exchange}
                tabs={[
                  { name: "Pair histories", value: "pair_history" },
                  { name: "Market histories", value: "market_history" },
                ]}
                tabActive={selectedTab}
                onChangeTabs={handleTabChange}
              />
              <button
                onClick={handleCloseDialog}
                className="text-white py-2 px-4 rounded absolute top-0 right-0"
              >
                <img className="rotate-45" src="/close.svg" alt="" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalHistory;

import { useState } from "react";
import Tokens from "../tokens";

type IProps = {
  tabs: any[];
  tabActive: string;
  onChangeTabs: (tabValue: string) => void;
  handleClickFunctionButton?: (type?: string) => void;
};

const ModalToken = ({
  tabs,
  tabActive,
  onChangeTabs,
  handleClickFunctionButton,
}: IProps) => {
  const [openDialog, setOpenDialog] = useState("");
  const [isExiting, setIsExiting] = useState(false);

  const handleOpenDialog = (type: any) => {
    if (isExiting) return;
    setIsExiting(true);
    setTimeout(() => {
      setOpenDialog(type);
      setIsExiting(false);
    }, 500);
  };

  const handleCloseDialog = () => {
    setIsExiting(true);
    setTimeout(() => {
      setOpenDialog("");
      setIsExiting(false);
    }, 500);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex gap-2">
        <span
          onClick={() => handleOpenDialog("modal2")}
          className=" text-white font-bold text-[20px] py-1 px-2 rounded cursor-pointer"
        >
          VNEX/USDT
        </span>
      </div>
      <div>
        {openDialog === "modal2" && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div
              className={`bg-black-1 p-6 rounded-lg shadow-lg absolute top-0 w-full h-1/2 transform transition-transform duration-300 ease-in-out ${
                isExiting ? "-translate-y-full" : "translate-y-0"
              }`}
            >
              <Tokens
                tabs={tabs}
                tabActive={tabActive}
                onChangeTabs={onChangeTabs}
                handleClickFunctionButton={handleClickFunctionButton}
              />
              <button
                onClick={handleCloseDialog}
                className="text-white py-2 px-4 rounded absolute top-0 right-0"
              >
                <img className="rotate-45" src="/close.svg" alt="" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalToken;

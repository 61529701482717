/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Tasks from "./Tasks";
import { IoMdDoneAll, IoMdCodeWorking } from "react-icons/io";
import { BsListTask } from "react-icons/bs";
import { compareDateWithCur } from "utils/times";
import TaskService from "api/tasks";
import { MasterContext } from "context/MasterContext";
import VTabs from "components/v-core/v-tabs";
import { useHistory } from "react-router-dom";

const View = () => {
  const { auth, getTabPositon } = useContext(MasterContext);
  const tabs = [
    {
      name: "Task List",
      value: "new",
      icon: <BsListTask className="w-full h-full" />,
    },
    {
      name: "Doing",
      value: "doing",
      icon: <IoMdCodeWorking className="w-full h-full" />,
    },
    {
      name: "Done",
      value: "done",
      icon: <IoMdDoneAll className="w-full h-full" />,
    },
  ];

  const [tasks, setTasks] = useState({
    news: [],
    doing: [],
    done: [],
  });

  const histoty = useHistory();
  const [listTasks, setListTasks] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");

  useEffect(() => {
    onGetTasks();
    if (!currentTab) histoty.push("?tab=new");
  }, []);

  const onGetTasks = async () => {
    const res: any = await TaskService.getTasks();
    setListTasks(res?.tasks);
  };

  const handleTabs = (tab: string) => {
    histoty.push(`?tab=${tab}`);
  };

  useEffect(() => {
    if (listTasks && listTasks.length >= 0) {
      const news = listTasks.filter(
        (task) =>
          !task.participants ||
          (!task.participants[auth?._id] &&
            compareDateWithCur(task.time.end_date) > 0)
      );
      const doing = listTasks.filter(
        (task) =>
          task.participants &&
          task.participants[auth?._id] &&
          task.participants[auth?._id]["status"] === "doing" &&
          compareDateWithCur(task.time.end_date) > 0
      );
      const done = listTasks.filter(
        (task) =>
          task.participants &&
          task.participants[auth?._id] &&
          task.participants[auth?._id]["status"] === "done"
      );

      setTasks({
        news,
        doing,
        done,
      });
    }
  }, [listTasks]);

  return (
    <div className="p-4 rounded-lg">
      {getTabPositon("tab_page_tasks").length > 0 ? (
        <VTabs
          tabs={getTabPositon("tab_page_tasks").map(
            (item: any, index: number) => ({
              name: item.name,
              value: item.key,
              icon: <img src={item?.img_icon} />,
            })
          )}
          onChangeTabs={handleTabs}
          tabActive={currentTab}
        />
      ) : (
        <VTabs tabs={tabs} onChangeTabs={handleTabs} tabActive={currentTab} />
      )}
      <div className="flex md:px-4 px-2 w-full justify-start items-center ">
        {currentTab === "new" && <Tasks listTasks={tasks.news || []} />}
        {currentTab === "doing" && <Tasks listTasks={tasks.doing || []} />}
        {currentTab === "done" && (
          <Tasks type="done" listTasks={tasks.done || []} />
        )}
      </div>
    </div>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */

import { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UncontrolledCollapse } from "reactstrap";
import { BiSupport, BiHistory } from "react-icons/bi";

import VInput from "components/v-core/v-input";
import Dropdown from "components/v-core/v-dropdown";
import UploadFile from "components/v-upload";
import VButton from "components/v-core/v-button";
import History from "./history";
import { useHistory } from "react-router-dom";

import { MasterContext } from "context/MasterContext";
import VTabs from "components/v-core/v-tabs";
import { ThemeContext } from "context/ThemeContext";
import SettingService from "api/settings";
import UploadService from "api/uploads";
import SupportService from "api/supports";
import { v4 as uuidv4 } from "uuid";

const tabs = [
  {
    name: "Supports",
    value: "support",
    icon: <BiSupport size={17} />,
  },
  {
    name: "History",
    value: "history",
    icon: <BiHistory size={17} />,
  },
];

const Support = () => {
  const { auth, getTabPositon } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [state, setState] = useState([]);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [supports, setSupports] = useState([]);
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");

  useEffect(() => {
    getSupports();
  }, []);

  useEffect(() => {
    if (supports)
      setState(
        (supports || []).map((q) => ({
          ...q,
          isOpen: false,
          listFiles: [],
        }))
      );
  }, [supports]);

  useEffect(() => {
    if (!currentTab) {
      history.push("?tab=support");
    }
  }, [currentTab]);

  const getSupports = async () => {
    try {
      const res: any = await SettingService.getSettingByName("supports");
      if (res && res?.setting) {
        setSupports(res.setting?.value);
      }
    } catch (error) {}
  };

  const handleTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
  };

  const handleSubmitForm = async (id: string) => {
    try {
      setLoading(true);
      const send = state.find((st) => st.id === id);
      const data = dataQuestion.find((el) => el.id === id);

      if (data) {
        delete data.id;
      }
      const newData = send.listFiles.filter((list) => list.file);
      if (newData.length > 0) {
        const formData = new FormData();

        newData.map((stNew) => {
          formData.append("files", stNew.file);
          return stNew;
        });

        const res: any = await UploadService.uploadMultiFile({
          body: formData,
        });

        if (res) {
          const listAttachments = res.map((el) => el.filePath);
          await SupportService.sendSupport({
            title: send.title,
            value: { ...data, attachments: listAttachments },
          });
        }
      } else {
        await SupportService.sendSupport({
          title: send.title,
          value: data,
        });
      }
      toast.success(`Send support success!`);
      setLoading(false);
      getSupports();
    } catch (error) {
      toast.error(`Error!`);
      setLoading(false);
    }
  };

  const handleAddAttachment = (id: string) => {
    setState((prev) =>
      prev.map((st) =>
        st.id === id
          ? {
              ...st,
              listFiles: st?.listFiles?.concat({
                id: uuidv4(),
                file: null,
              }),
            }
          : { ...st }
      )
    );
  };

  const removeChil = (id: string, fileId: number) => {
    setState((prev) =>
      prev.map((st) =>
        st.id === id
          ? {
              ...st,
              listFiles: st.listFiles.filter((el) => el.id !== fileId),
            }
          : st
      )
    );
  };

  const handleChangeFields = (
    e: any,
    id: string,
    title: string,
    key: string,
    typeFields: string
  ) => {
    const qs = dataQuestion.find((el) => el.id === id);
    if (qs) {
      setDataQuestion((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                [key]: typeFields === "input" ? e.target.value : e.value,
              }
            : el
        )
      );
    } else {
      setDataQuestion(
        dataQuestion.concat({
          id: id,
          [key]: typeFields === "input" ? e.target.value : e.value,
        })
      );
    }
  };

  const setFile = (f: any, id: string, key: string, parentId: string) => {
    setState((prev) =>
      prev.map((st) =>
        st.id === parentId
          ? {
              ...st,
              listFiles: st.listFiles.map((el: any) =>
                el.id === id
                  ? {
                      ...el,
                      file: f,
                    }
                  : { ...el }
              ),
            }
          : { ...st }
      )
    );
  };

  return (
    <div className=" w-full">
      {getTabPositon("tab_page_supports").length > 0 ? (
        <VTabs
          tabs={getTabPositon("tab_page_supports").map(
            (item: any, index: number) => ({
              name: item.name,
              value: item.key,
              icon: <img src={item?.img_icon} />,
            })
          )}
          onChangeTabs={handleTabs}
          tabActive={currentTab}
        />
      ) : (
        <VTabs tabs={tabs} onChangeTabs={handleTabs} tabActive={currentTab} />
      )}
      {currentTab === "history" && <History />}
      {currentTab === "support" && (
        <div
          className={`rounded-md w-full overflow-hidden p-3 ${
            window.innerWidth <= 420 ? "p-1" : "p-3"
          }`}
        >
          <div>
            {state.map((item, index) => (
              <div className={item.isOpen} key={index}>
                <div>
                  <button
                    style={themesSetting?.card}
                    id={`toggler_${item.id}`}
                    className="rounded-md cursor-pointer pt-2 p-2 border-1 border-solid h-12 border-opacity-10 opacity-0.1 mb-3 font-medium w-full text-left"
                  >
                    {item.title}
                  </button>
                  <UncontrolledCollapse toggler={`toggler_${item.id}`}>
                    <div className="visible border-none">
                      <div className={"border-0"}>
                        <div>
                          <VInput
                            transparent={true}
                            label={"Name"}
                            disabled={true}
                            defaultValue={
                              auth?.fullName || auth?.userName || ""
                            }
                          />
                          <VInput
                            transparent={true}
                            label={"Email"}
                            defaultValue={auth?.email || ""}
                            disabled={true}
                          />
                          {item?.fields.length > 0 &&
                            item.fields.map((el: any) => (
                              <>
                                {el.typeField === "input" && (
                                  <VInput
                                    transparent={true}
                                    placeholder={el.placeholder}
                                    label={el.label}
                                    onChange={(e) =>
                                      handleChangeFields(
                                        e,
                                        item.id,
                                        item.title,
                                        el.label,
                                        "input"
                                      )
                                    }
                                  />
                                )}
                                {el.typeField === "dropdown" && (
                                  <Dropdown
                                    label={el.label}
                                    options={el?.options?.map((el: any) => {
                                      return {
                                        label: el?.name,
                                        value: el?.name,
                                      };
                                    })}
                                    value={el.placeholder}
                                    onChange={(e) =>
                                      handleChangeFields(
                                        e,
                                        item.id,
                                        item.title,
                                        el.label,
                                        "dropdown"
                                      )
                                    }
                                  />
                                )}
                                {el.typeField === "textarea" && (
                                  <VInput
                                    transparent={true}
                                    height="100px"
                                    type="textarea"
                                    placeholder={el.placeholder}
                                    label={el.label}
                                    onChange={(e) =>
                                      handleChangeFields(
                                        e,
                                        item.id,
                                        item.title,
                                        el.label,
                                        "input"
                                      )
                                    }
                                  />
                                )}
                              </>
                            ))}
                          {item.attachments.enable && (
                            <div className="mt-10">
                              <h4 className="text-base">
                                Follow the requirements for screens:
                              </h4>
                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html: item.attachments.guide,
                                }}
                                className="text-sm"
                              ></div> */}
                              <br />
                              <div className="flex flex-wrap items-center">
                                {item?.listFiles?.length > 0 &&
                                  item?.listFiles?.map((at: any) => (
                                    <Fragment key={at.id}>
                                      <div className="relative overflow-hidden w-200 h-200 flex items-center m-10">
                                        <div className="absolute top-0 right-0 z-[999]">
                                          <VButton
                                            onClick={() =>
                                              removeChil(item.id, at.id)
                                            }
                                            className={`h-6 w-6 rounded-md p-3`}
                                          >
                                            -
                                          </VButton>
                                        </div>
                                        <div className="w-40 h-40">
                                          <UploadFile
                                            id={at.id}
                                            // sectionName={at.id}
                                            ratio="340x340"
                                            file={at.file}
                                            setFile={setFile}
                                            fileId={item.id}
                                          />
                                        </div>
                                      </div>
                                    </Fragment>
                                  ))}
                                <div
                                  onClick={() => handleAddAttachment(item.id)}
                                  className="m-1 h-16 w-16 cursor-pointer border-dashed border-2 border-gray-300 flex items-center justify-center"
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="py-5">
                            <VButton
                              loading={loading}
                              onClick={() => handleSubmitForm(item.id)}
                              className="w-[100px]"
                            >
                              Send
                            </VButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Support;

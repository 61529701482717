import React, { useState } from "react";
import VTable from "components/v-core/v-table";
import VTabs from "components/v-core/v-tabs";

const Histories = ({ data = [], tabs, tabActive, onChangeTabs }) => {
  const [selectedTab, setSelectedTab] = useState(tabActive || "pair_history");

  const columnsPair = [
    { field: "user", title: "User" },
    { field: "price", title: "Price" },
    { field: "amount", title: "From (CVXM)" },
    { field: "total", title: "To (USDT)" },
  ];

  const columnsMarket = [
    { field: "user", title: "User" },
    { field: "price", title: "Price" },
    { field: "amount", title: "From" },
    { field: "total", title: "To" },
    { field: "status", title: "Status" },
  ];

  const filteredData =
    selectedTab === "pair_history"
      ? data.filter((item) => item.type === "pair")
      : data.filter((item) => item.type === "market");

  const columns = selectedTab === "pair_history" ? columnsPair : columnsMarket;

  return (
    <div className="no-scrollbar border-gray-600 dark:border-gray-200 pt-1">
      <VTabs
        tabs={tabs}
        tabActive={selectedTab}
        onChangeTabs={(tab) => {
          setSelectedTab(tab);
          onChangeTabs(tab);
        }}
      />
      <div className="text-white">
        <VTable
          isNobackground
          isNoBorder
          columns={columns}
          data={filteredData}
        />
      </div>
    </div>
  );
};

export default Histories;

import TransactionService from "api/transactions";
import VPagination from "components/v-core/v-pagination";
import VTable from "components/v-core/v-table";
import Icons from "helper/icons";
import React, { useEffect, useState } from "react";
import {
  renderAmount,
  renderStatus,
  renderStock,
  renderTransactionType,
} from "utils/render";

type IProps = {
  package_id?: String;
};

const HistoryDividend = (props: IProps) => {
  const { package_id } = props;

  const [histories, setHistories] = useState<any>({
    docs: [],
    totalPages: 0,
    totalDocs: 0,
  });
  const [filter, setFilter] = useState({ page: 1, limit: 6 });

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (data: any) => <span>{data.from.slice(0, 20)}...</span>,
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (data: any) => <span>{data.to.slice(0, 20)}...</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <span>
          {renderAmount(data.amount)}({renderStock(data.token)})
        </span>
      ),
    },
    {
      title: "Amount Locked",
      dataIndex: "amountLocked",
      key: "amountLocked",
      render: (data: any) => (
        <span>
          {renderAmount(data.amountLocked)}({renderStock(data.token)})
        </span>
      ),
    },
    {
      title: "Amount Received",
      dataIndex: "amountReceived",
      key: "amountReceived",
      render: (data: any) => (
        <span>
          {renderAmount(data.amountReceived || data.amount)}(
          {renderStock(data.token)})
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (data: any) => <span>{renderTransactionType(data.type)}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => renderStatus(data.status),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item: any) => (
        <span>{new Date(item.createdAt).toLocaleString()}</span>
      ),
    },
    {
      key: "hash",
      title: "Hash",
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.hash?.slice(0, 10)}...
        </a>
      ),
    },
    {
      key: "invoice",
      title: "Invoice",
      render: (item: any) => (
        <a
          href={`/invoice/transactions/dividend/${item._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          <Icons iconClassName="w-8 h-8" iconName="view" />
        </a>
      ),
    },
  ];

  useEffect(() => {
    getHistories();
  }, []);

  const getHistories = async () => {
    try {
      const res = await TransactionService.getTransactions({
        query: {
          type: "dividend",
          package_id,
        },
      });
      setHistories(res);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const onFilter = (type: string) => {
    if (type === "next") {
      setFilter((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        page: prev.page - 1,
      }));
    }
  };

  return (
    <>
      <VTable columns={columns} data={histories.docs} />
      <VPagination
        page={filter?.page}
        totalPages={histories?.totalPages}
        limit={filter?.limit}
        totalDocs={histories?.totalDocs}
        onFilter={onFilter}
      />
    </>
  );
};

export default HistoryDividend;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable indent */
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { FaPlay } from "react-icons/fa";
import axios from "axios";
import APIs from "api";

let listTotal = [];
const View = () => {
  //   const [clientHeight, setClientHeight] = useState(600);
  const [videos, setVideos] = useState([]);
  // const [clientWidth, setClientWidth] = useState(500);
  //   const [frameWidth, setFrameWidth] = useState(0);
  const [id, setId] = useState();

  const [channels, setChannels] = useState([]);

  //   useEffect(() => {
  //     const doc = document.getElementById("root");
  //     setClientHeight(doc.clientHeight);
  //   }, []);

  const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive relative w-full pb-[56.25%]">
      <iframe
        className="absolute top-0 left-0"
        width={"100%"}
        height={"100%"}
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );

  const onPlay = (item) => {
    setId(item.id);
    setVideos(listTotal.filter((index) => index.id !== item.id));
  };

  const onGetChannels = async () => {
    try {
      const res: any = await APIs.SETTING.getSettingByName("channel");
      if (res && res?.setting) {
        setChannels(res.setting?.value);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const getVideos = async () => {
      const _videos = await Promise.all(
        channels.map(async (item) => {
          const res = await axios.get(
            `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${item.id}&key=AIzaSyBIcdVOywmTMvcJihZPZmaCdRsIsaKlnFo`
          );
          return res.data.items[0]
            ? {
                ...res.data.items[0]["snippet"],
                prioritized: item.prioritized,
                id: item.id,
              }
            : null;
        })
      );
      const listSort = _videos.filter((_) => _);
      listSort.sort((a, b) => (!a.prioritized && b.prioritized ? 1 : -1));
      let idStart = channels[0]?.id;
      setVideos(listSort.filter((item) => item.id !== idStart));
      listTotal = listSort;
    };
    if (channels && channels.length > 0) {
      getVideos();
      setId(channels[0].id);
    }
  }, [channels]);

  useEffect(() => {
    onGetChannels();
    // const width = document.getElementsByClassName("clientWidth")[0].clientWidth;
    // setClientWidth(width);
    // const fWidth = document.getElementsByClassName("frameWidth")[0].clientWidth;
    // setFrameWidth(fWidth);
  }, []);

  return (
    <div>
      <div className="w-full min-h-[500px] p-3 md:p-[30px] rounded-lg">
        <Row>
          <Col xs={12} className="clientWidth">
            <YoutubeEmbed embedId={id} />
            <hr />
            <div className="flex flex-wrap">
              {videos.map((item) => (
                <div
                  className="md:w-1/4 w-1/2 group cursor-pointer p-2 "
                  onClick={() => onPlay(item)}
                >
                  <div className="relative">
                    <div className="absolute-center group-hover:text-red-500">
                      <FaPlay size={27} />
                    </div>
                    <img src={item.thumbnails.high.url} alt="" />
                  </div>
                  <span>{item.title.substr(0, 55) || +"..."}</span>
                </div>
              ))}
            </div>
          </Col>
          {/* <Col xs={12} className="frameWidth">
          <Logo>
            <img src="/assets/images/VDB-64.png" alt="" />
            <h4>Coming soon</h4>
          </Logo>
          {frameWidth > 0 && (
            <iframe
              src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FVzonex-Project-101194846036816%2F&tabs=timeline&width=${frameWidth}&height=600&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
              height="600"
              width={frameWidth}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          )}
          <div>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="vzonex"
              theme="dark"
              options={{ height: clientHeight }}
            />
          </div>
        </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default View;

import React, { useState, useEffect } from "react";
import VModal from "components/v-core/v-modal";
import APIs from "api";
import VInput from "components/v-core/v-input";

const assetsOptions = [
  "VAS",
  "CVXM201",
  "CVXM",
  "CVXM2",
  "USDV",
  "CVX",
  "USDT",
];

const Store = ({ modalIsOpen, closeModal }) => {
  const [store, setStore] = useState([]);
  const [formData, setFormData] = useState({
    user_id: "",
    remark: "",
    address: "",
    chain_type: "",
  });
  const [selectedAsset, setSelectedAsset] = useState(assetsOptions[0]);

  useEffect(() => {
    fetchStore();
  }, []);

  const fetchStore = async () => {
    try {
      const data: any = await APIs.STORE.getStore({
        params: { userId: formData.user_id },
      });
      if (data) setStore(data);
    } catch (error) {
      console.error("Error fetching store:", error);
    }
  };

  const handleInputChange = (e) => {
    console.log("Name:", e.target.name, "Value:", e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await APIs.STORE.createTransferStore(formData);
      fetchStore();
      closeModal();
    } catch (error) {
      console.error("Error adding store:", error);
    }
  };

  return (
    <div className="bg-slate-50">
      <VModal
        isOpen={modalIsOpen}
        title="Add Store"
        onCancel={closeModal}
        onConfirm={handleSubmit}
        confirmLabel="Confirm"
        cancelLabel="Close"
      >
        <div className="space-y-4">
          <div className="bg-yellow-100 text-yellow-800 p-3 rounded text-sm">
            <p>
              ⚠️ Note: Once successfully added, your withdrawal address cannot
              be modified.
            </p>
          </div>

          <select
            className="w-full p-2 border border-gray-300 rounded mt-2 text-black-1"
            value={selectedAsset}
            onChange={(e) => setSelectedAsset(e.target.value)}
          >
            {assetsOptions.map((asset) => (
              <option key={asset} value={asset}>
                {asset}
              </option>
            ))}
          </select>

          <div className="flex items-center">
            <label className="text-gray-400 mr-2">
              Save as Universal store Address
            </label>
            <input type="checkbox" />
          </div>

          <VInput
            transparent={true}
            className="text-black-1"
            type="text"
            placeholder="Please input your withdrawal store address"
            name="address"      
            onChange={handleInputChange}
          />

          <VInput
            className="text-black-1"
            type="text"
            placeholder="Chain Type"
            name="chain_type"
            onChange={handleInputChange}
          />

          <VInput
            className="text-black-1"
            placeholder="Add a remark"
            name="remark"
            onChange={handleInputChange}
          />

          <div className="flex items-center">
            <input type="checkbox" className="mr-2" />
            <label className="text-gray-400">
              No verification needed for this address next time
            </label>
          </div>
        </div>
      </VModal>
    </div>
  );
};
export default Store;
